import { NextPage } from 'next'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { isShopeeApp, popWebView, webBridgeLogin } from 'shared/webBridge'

const LoginPage: NextPage = () => {
  const router = useRouter()
  const { query, isReady } = router

  useEffect(() => {
    if (!isShopeeApp()) router.replace('/pending')
  }, [])

  useEffect(() => {
    if (!isReady) return

    webBridgeLogin(query.site as string)

    //* If user cancel the login, pop the view to return to our microsite
    const handleReturnFromLogin = () => {
      if (document.visibilityState === 'visible') {
        popWebView()
        document.removeEventListener('visibilitychange', handleReturnFromLogin, false)
      }
    }
    document.addEventListener('visibilitychange', handleReturnFromLogin, false)
  }, [isReady])

  return (
    <>
      <Head>
        <title>Quiz - Login</title>
        <meta name="description" content="Quiz login" />
      </Head>
    </>
  )
}

export default LoginPage
